<template>
  <div class="test-form">
    <parser :form-conf="formConf" @submit="sumbitForm1" />
    <!-- <parser :key="key2" :form-conf="formConf" @submit="sumbitForm2" /> -->
    <el-button @click="change">change</el-button>
  </div>
</template>

<script>
import Parser from "../Parser";

// 若parser是通过安装npm方式集成到项目中的，使用此行引入
// import Parser from 'form-gen-parser'

export default {
  components: {
    Parser
  },
  props: {},
  data() {
    return {
      key2: +new Date(),
      formConf: {
        fields: [
          {
            __config__: {
              label: "按钮",
              showLabel: true,
              changeTag: true,
              labelWidth: null,
              tag: "el-button",
              tagIcon: "button",
              span: 24,
              layout: "colFormItem",
              document: "https://element.eleme.cn/#/zh-CN/component/button",
              renderKey: 1594288459289
            },
            __slot__: {
              default: "测试按钮1"
            },
            type: "primary",
            icon: "el-icon-search",
            round: false,
            size: "medium",
            plain: false,
            circle: false,
            disabled: false,
            on: {
              click: "clickTestButton1"
            }
          }
        ],
        __methods__: {
          clickTestButton1() {
            console.log(
              `%c【测试按钮1】点击事件里可以访问当前表单：
                1) formModel='formData', 所以this.formData可以拿到当前表单的model
                2) formRef='elForm', 所以this.$refs.elForm可以拿到当前表单的ref(vue组件)
              `,
              "color:#409EFF;font-size: 15px"
            );
            console.log("表单的Model：", this.formData);
            console.log("表单的ref：", this.$refs.elForm);
          }
        },
        formRef: "elForm",
        formModel: "formData",
        size: "small",
        labelPosition: "right",
        labelWidth: 100,
        formRules: "rules",
        gutter: 15,
        disabled: false,
        span: 24,
        formBtns: true,
        unFocusedComponentBorder: false
      }
    };
  },
  computed: {},
  watch: {
    formConf(newVal, oldVal) {
      console.log("newVal", newVal)
      console.log("oldVal", oldVal)
    }
  },
  created() {},
  mounted() {
    console.log(this.formConf.fields)
    this.$Api.Form.getTeaForm(9)
      .then(res => {
        console.log(JSON.parse(res.content))
        this.formConf.fields = this.formConf.fields.concat(
          JSON.parse(res.content)
        )
        console.log(this.formConf.fields)
      })
      .catch(err => console.log(err))

    // 表单数据回填，模拟异步请求场景
    // setTimeout(() => {
    //   // 请求回来的表单数据
    //   const data = {
    //     mobile: "18836662555",
    //   };
    //   // 回填数据
    //   this.fillFormData(this.formConf, data);
    //   // 更新表单
    //   this.key2 = +new Date();
    // }, 2000);
  },
  methods: {
    // fillFormData(form, data) {
    //   form.fields.forEach((item) => {
    //     const val = data[item.__vModel__];
    //     if (val) {
    //       item.__config__.defaultValue = val;
    //     }
    //   });
    // },
    change() {
      this.key2 = +new Date();
      const t = this.formConf;
      this.formConf = this.formConf2;
      this.formConf2 = t;
    },
    sumbitForm1(data) {
      console.log("sumbitForm1提交数据：", data);
    },
    sumbitForm2(data) {
      console.log("sumbitForm2提交数据：", data);
    }
  }
};
</script>

<style lang="scss" scoped>
.test-form {
  margin: 15px auto;
  width: 800px;
  padding: 15px;
}
</style>
